.activities .container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.form-check-label {
  padding: 1em;
  color: #333;
}

.form-check-input {
  margin-top: 1em;
  margin-bottom: 1em;
}

.form-check {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: #333 solid 1px;
  border-radius: 5px;
  margin: 0.5em;
}

.form-check:has(:checked) {
  background-color: #f0f0f0;
  border: black solid 2px;
}

.form-check:hover {
  background-color: #f0f0f0;
}
