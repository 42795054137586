body {
  text-align: center;
  background-color: #333;
}

.content {
  background-color: white;
  width: 80%;
  margin: auto;
  margin-right: 5%;
}

.logo {
  height: 100%;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 10;
  overflow: hidden;
  background-color: "white";
}

li {
  float: left;
}

li a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

li a:hover {
  background-color: #111;
}

@media screen and (max-width: 800px) {
  h2, label {
    color: #f0f0f0;
  }
}