#bottomnav {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #333;
  color: white;
  text-align: center;
  display: flex;
  z-index: 1;
}

#bottomnav a {
  padding: 0.5em;
  flex-grow: auto;
  color: white;
  text-decoration: none;
  float: left;
  font-weight: lighter;
  width: 20%;
}

#bottomnav i {
  margin-right: 10px;
  text-align: center;
}

#bottomnav-header {
  position: relative;
  top: 0;
  width: 100%;
  background-color: #333;
  display: flex;
  z-index: 1;
  padding-left: 1em;
  align-items: center;
}

#bottomnav-header img {
  height: auto;
  width: 5em;
  padding: 0.5em;
  margin-left: auto;
  margin-right: auto;
}

#bottomnav-header p {
  color: orange;
  font-weight: "strong";
  margin: auto;
  padding: 0.5em;
}

#sidenav {
  height: 100%;
  width: 15%;
  position: fixed;
  z-index: 1;
  top: 10;
  left: 0;
  background-color: #333;
}

#sidenav a {
  padding: 8% 0 8% 8%;
  display: block;
  color: white;
  text-decoration: none;
  text-align: left;
}

#sidenav i {
  margin-right: 10px;
}

#sidenav p {
  color: orange;
  font-weight: "strong";
}

#sidenav a:hover {
  background-color: #555;
}

#content {
  margin-left: 200px;
  padding: 20px;
  flex: 1;
}

@media screen and (max-width: 800px) {
  ul {
    display: none;
  }

  .content {
    width: 100%;
    padding-bottom: 4em;
    padding-top: 3em;
  }
}
