h1 {
    text-align: center;
    background-color: #333;
    color: white;
    padding-bottom: 0.5em;
}

h2 {
    margin-bottom: 0.5em;
}

.admin-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
}

.admin-container i {
    font-size: larger;
}

.approval-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.approval-container h3 {
    width: 100%;
    padding-top: 1em;
    border-top: #333 1px solid;
}

.approval-card {
    width: 20%;
    height: 15em;
    margin: 1em;
    padding: 0.5em;
    border: 1px solid #333;
    border-radius: 10px;
}

.button-container {
    display: flex;
    justify-content: space-evenly;
}

button {
    background-color: orange;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 0.5em;
    margin-top: 0.5em;
    margin-right: 1em;
    margin-left: 1em;
    margin-bottom: 0.5em;
}

@media screen and (max-width: 800px) {
    h1 {
        color: #f0f0f0;
    }
    h2 {
        color: #f0f0f0;
    }
    .admin-container {
        color: #f0f0f0;
    }
    .approval-container {
        color: #f0f0f0;
    }
    .approval-card {
        color: #f0f0f0;
        width: 40%;
        border: 1px solid #f0f0f0;
    }
    button {
        color: #f0f0f0;
        margin-bottom: 5em;
    }
    
}