h1 {
  text-align: center;
  background-color: #333;
  color: white;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  float: left;
  width: 100%;
  margin-top: 1em;
  margin-bottom: 1em;
}

.card-container h3 {
  width: 100%;
  color: white;
  padding-top: 1em;
  border-top: #ccc 1px solid;
}

.card {
  width: 30%;
  height: 25em;
  margin: 1em;
  padding: 0.5em;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #333;
  color: white;
}

.card h2 {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}

.card p {
  margin-bottom: 0.5em;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  height: 12em;
}

.card img {
  width: 90%;
  height: 45%;
  margin-left: auto;
  margin-right: auto;
}

.card button {
  background-color: orange;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.summary {
  width: 100%;
  margin-top: 1em;
  margin-bottom: 1em;
  padding: 1em;
  background-color: #333;
  color: white;
}

.summary img {
  margin-bottom: 3em;
  width: 25%;
}

.summary h2 {
  margin-bottom: 1em;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}

.summary p {
  margin-bottom: 1em;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}

.summary button {
  margin-top: 3em;
  background-color: orange;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 800px) {
  .content {
    background-color: #333;
  }

  .card {
    width: 75%;
  }

  .card-container {
    justify-content: center;
    margin-bottom: 5em;
  }

  .summary img {
    width: 50%;
  }

  .summary button {
    width: 90%;
  }
}